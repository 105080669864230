import { Grid, Paper, Stack, Typography } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useQuery } from "@tanstack/react-query"
import { Span } from "components/common"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useToggle } from "react-use"
import { tourService } from "services"
import { formatDateTime, formatDateWithTimeZone } from "utils/common"

import {
  TourBookingCancelActionDialog,
  TourBookingDepositDialog,
  TourBookingEditDialog,
  TourBookingPaymentDialog,
  TourBookingViewActions,
  TourStatusChip,
} from "./components"

type TourBookingPassengerPriceInfo = {
  count: number
  label: string
  price: number
  total: number
}

const vehiclesName = {
  AIRPLANE: "Máy bay",
  CAR: "Xe ô tô",
  CUSTOM: "Tự chọn",
  HIGH_SPEED_TRAIN: "Tàu cao tốc",
  TRAIN: "Tàu hỏa",
}

const renderTableRows = (booking: TourBooking) => {
  const prices: TourBookingPassengerPriceInfo[] = [
    {
      count: booking.numberAdults,
      label: "Người lớn (Từ 11 tuổi trở lên)",
      price: booking.adultsPrice,
      total: booking.numberAdults * booking.adultsPrice,
    },
  ]
  if (booking.numberChildren1) {
    prices.push({
      count: booking.numberChildren1,
      label: "Trẻ em (ngủ ghép - Từ 2-5 tuổi)",
      price: booking.children1Price,
      total: booking.numberChildren1 * booking.children1Price,
    })
  }
  if (booking.numberChildren2) {
    prices.push({
      count: booking.numberChildren2,
      label: "Trẻ em (Từ 5-11 tuổi)",
      price: booking.children2Price,
      total: booking.numberChildren2 * booking.children2Price,
    })
  }
  if (booking.numberInfant) {
    prices.push({
      count: booking.numberInfant,
      label: "Em bé (Dưới 2 tuổi)",
      price: booking.infantPrice,
      total: booking.numberInfant * booking.infantPrice,
    })
  }
  return prices
}

const TourBookingListView = () => {
  const [isOpenBookingCancel, onToggleBookingCancel] = useToggle(false)
  const [isOpenEditBooking, onToggleEditBooking] = useToggle(false)
  const [isOpenPaymentBooking, onTogglePaymentBooking] = useToggle(false)
  const [isOpenDepositBooking, onToggleDepositBooking] = useToggle(false)
  const [tourActionType, setTourActionType] = useState<"CANCEL" | "REFUND" | "REJECT">("CANCEL")
  const { code } = useParams()

  const {
    data: booking,
    isError,
    isLoading,
  } = useQuery({
    queryFn: () => tourService.getBooking({ id: Number(code) }),
    queryKey: ["tourService.getBooking", code],
    refetchOnMount: true,
  })

  if (isLoading) return <div>Đang tải dữ liệu...</div>
  if (isError) return <div>Lỗi tải dữ liệu tour</div>
  if (!booking || Object.keys(booking).length === 0) {
    return <div>Không có dữ liệu</div>
  }

  const handleOpenCancelOrRefundBookingTour = (tour: TourBooking, action?: string) => {
    if (action === "REJECT") {
      setTourActionType("REJECT")
    } else if (["RESERVED"].includes(booking.status)) {
      setTourActionType("CANCEL")
    } else if (["CANCELLATION_REQUESTED", "DEPOSITED", "PAID"].includes(tour.status)) {
      setTourActionType("REFUND")
    }
    onToggleBookingCancel(true)
  }

  return (
    <Stack gap={3}>
      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Thông tin đặt chỗ
          </Typography>

          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Mã đặt chỗ: </span>
                <span className="text-blue-600 text-xl font-bold text-info">{booking.bookingCode}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Trạng thái: </span>
                <TourStatusChip booking={booking} />
              </Typography>
              {booking.requestPaymentAmount > 0 && (
                <Typography>
                  <Span className="font-semibold text-error">Số tiền đã yêu cầu: </Span>
                  <Span className="text-xl font-bold text-error">{booking.requestPaymentAmount.toLocaleString()}</Span>
                </Typography>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Thời gian giữ chỗ: </span>
                <span className="font-semibold">{formatDateWithTimeZone(booking.bookingExpirationDate)}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Thời gian đặt tour: </span>
                <span className="font-semibold">{formatDateWithTimeZone(booking.createdAt)}</span>
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Thông tin liên hệ
          </Typography>

          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Họ tên khách hàng: </span>
                <span className="font-semibold">{booking.contactName}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Điện thoại: </span>
                <span className="font-semibold">{booking.contactPhone}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Email: </span>
                <span className="font-semibold">{booking.email}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Địa chỉ: </span>
                <span className="font-semibold">{booking.address}</span>
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            {booking.tour.tourName}
          </Typography>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Mã tour: </span>
                <span className="font-semibold">{booking.tour.tourCode}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Thời gian: </span>
                <span className="font-semibold">
                  {booking.tour.tourTotalDay}N{booking.tour.tourTotalNight}Đ
                </span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Địa điểm khởi hành: </span>
                <span className="text-blue-600 font-semibold">{"TP Hồ Chí Minh"}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Ngày khởi hành: </span>
                <span className="text-blue-600 font-semibold">{new Date(booking.startDate).toLocaleDateString()}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Khách sạn: </span>
                <span className="text-blue-600 font-semibold">{booking.price.levelNam}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <span className="font-medium">Ngày về: </span>
                <span className="text-blue-600 font-semibold">{new Date(booking.endDate).toLocaleDateString()}</span>
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography>
                <Span className="font-medium">Phương tiện: </Span>
                <Span className="font-semibold">
                  {vehiclesName[booking.vehiclesGo]}
                  {booking.vehiclesBack && booking.vehiclesBack !== booking.vehiclesGo
                    ? ` - ${vehiclesName[booking.vehiclesBack]}`
                    : ""}
                </Span>
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Chi tiết đặt chỗ
          </Typography>

          <DataGrid
            columns={([] as GridColDef<TourBookingPassengerPriceInfo>[]).concat([
              {
                field: "label",
                headerName: "Loại hành khách",
                minWidth: 260,
                sortable: false,
              },
              {
                field: "price",
                flex: 1,
                headerName: "Giá tour",
                minWidth: 150,
                renderCell: ({ row: item }) => <Typography>{item.price.toLocaleString()}</Typography>,
                sortable: false,
              },
              {
                field: "count",
                flex: 1,
                headerName: "Số lượng",
                minWidth: 100,
                sortable: false,
              },
              {
                field: "total",
                headerName: "Thành tiền",
                minWidth: 180,
                renderCell: ({ row: item }) => (
                  <Typography fontWeight="bold" variant="h6">
                    {item.total.toLocaleString()}
                  </Typography>
                ),
                sortable: false,
              },
            ])}
            getRowId={(row) => row.label}
            hideFooter
            paginationMode="client"
            rows={renderTableRows(booking)}
          />

          <Stack gap={1}>
            <Typography align="right" className="font-semibold">
              Tổng tiền: <Span className="text-lg font-bold">{booking.totalPrice.toLocaleString()}</Span>
            </Typography>
            {!booking.createdBy && (
              <Typography align="right" className="font-semibold">
                Đã cọc / thanh toán:{" "}
                <Span className="text-lg font-bold text-success">
                  {(booking.totalAmountPaid + booking.totalPriceRefund).toLocaleString()}
                </Span>
              </Typography>
            )}
            <Typography align="right" className="font-semibold ">
              Hoa hồng {booking.createdBy ? "hãng" : "F2"}:{" "}
              <Span className="text-lg font-bold text-info">
                {booking.createdBy
                  ? booking.totalCommission.toLocaleString()
                  : booking?.promotion?.totalAgencyPromotion?.toLocaleString() ?? 0}
              </Span>
            </Typography>
            {!booking.createdBy && booking.totalPriceRefund > 0 && (
              <Typography align="right" className="font-semibold">
                Đã hoàn:{" "}
                <Span className="text-lg font-bold text-warning">{booking.totalPriceRefund.toLocaleString()}</Span>
              </Typography>
            )}
          </Stack>
        </Stack>
      </Paper>

      <Paper className="rounded p-3 shadow-md" variant="outlined">
        <Stack spacing={2}>
          <Typography className="font-semibold" variant="h5">
            Lịch sử thao tác
          </Typography>

          <DataGrid
            columns={([] as GridColDef<AuditLog>[])
              .concat([
                {
                  field: "createdAt",
                  headerName: "Thời gian",
                  minWidth: 180,
                  renderCell: ({ row: item }) => <Typography>{formatDateTime(item.createdAt)}</Typography>,
                  sortable: false,
                },
              ])
              .concat([
                {
                  field: "action",
                  flex: 1,
                  headerName: "Thao tác",
                  minWidth: 180,
                  renderCell: ({ row: item }) => <Typography>{item.action}</Typography>,
                  sortable: false,
                },
                {
                  field: "newValue",
                  flex: 1,
                  headerName: "Chi tiết",
                  minWidth: 180,
                  renderCell: ({ row: item }) => <Typography className="truncate">{item.newValue}</Typography>,
                  sortable: false,
                },
                {
                  field: "modifiedBy",
                  headerName: "Người thực hiện",
                  minWidth: 300,
                  renderCell: ({ row: item }) => (
                    <Typography>
                      {item.modifiedBy
                        ? item?.modifiedBy?.firstName && item?.modifiedBy?.lastName
                          ? `${item?.modifiedBy?.firstName} ${item?.modifiedBy?.lastName}`
                          : item?.modifiedBy?.username
                        : item?.bookerInfo}
                    </Typography>
                  ),
                  sortable: false,
                },
              ])}
            hideFooter
            paginationMode="client"
            rows={booking.auditLogs}
          />
        </Stack>
      </Paper>

      <TourBookingViewActions
        booking={booking}
        onCancelOrRefund={() => handleOpenCancelOrRefundBookingTour(booking)}
        onDeposit={() => onToggleDepositBooking(true)}
        onEdit={() => onToggleEditBooking(true)}
        onPayment={() => onTogglePaymentBooking(true)}
        onReject={() => handleOpenCancelOrRefundBookingTour(booking, "REJECT")}
      />

      <TourBookingCancelActionDialog
        actionType={tourActionType}
        booking={booking}
        onClose={() => onToggleBookingCancel(false)}
        open={isOpenBookingCancel}
      />

      <TourBookingDepositDialog
        booking={booking}
        onClose={() => onToggleDepositBooking(false)}
        open={isOpenDepositBooking}
      />
      <TourBookingEditDialog
        booking={booking}
        isUpdateNote={false}
        onClose={() => onToggleEditBooking(false)}
        open={isOpenEditBooking}
      />
      <TourBookingPaymentDialog
        booking={booking}
        onClose={() => onTogglePaymentBooking(false)}
        open={isOpenPaymentBooking}
      />
    </Stack>
  )
}

export default TourBookingListView
