import { client } from "./axios"

const getCategory = (params?: CategoryParams): Promise<PaginateResponse<TourCategory>> =>
  client.get(`/tours-portal/category`, { params })
const fetchTourRows = (params?: TourRowParams): Promise<PaginateResponse<TourRow>> =>
  client.get(`/tours-portal/row`, { params })
const getTourRow = ({ id }: ByID): Promise<TourRow> => client.get(`/tours-portal/row/${id}`)
const getTourCalendar = ({ id }: ByID): Promise<Array<TourCalendar>> =>
  client.get(`/tours-portal/calendar/list/${id}`, { params: { size: 1e3 } })

const getBooking = ({ id }: ByID): Promise<TourBooking> => client.get(`/tours-portal/booking/${id}`)
const fetchBookings = (params?: PaginateParams): Promise<PaginateResponse<TourBooking>> =>
  client.get(`/tours-portal/booking`, { params })

const createBooking = (body: TourBookingCreateBody): Promise<TourBookingCreateData> =>
  client.post(`/tours-portal/booking`, body)
const updateBooking = ({ id, ...body }: TourBookingUpdateBody): Promise<TourBooking> =>
  client.patch(`/tours-portal/booking/${id}`, body)

const bookingRequestPayment = ({ id, ...body }: TourBookingRequestPaymentBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/request_payment`, body)
const bookingCancel = ({ id, ...body }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/cancel`, body)
const bookingReject = ({ id, ...body }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/reject`, body)
const bookingRefund = ({ id, ...body }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/refund`, body)
const bookingSure = ({ id }: TourBookingCancelOrRefundBody): Promise<string> =>
  client.post(`/tours-portal/booking/${id}/sure`)
const exportBooking = (body: TourBookingExportParams): Promise<Blob> =>
  client.post(`/tours-portal/booking/export`, body, { responseType: "blob" })

const tourService = {
  bookingCancel,
  bookingRefund,
  bookingReject,
  bookingRequestPayment,
  bookingSure,
  createBooking,
  exportBooking,
  fetchBookings,
  fetchTourRows,
  getBooking,
  getCategory,
  getTourCalendar,
  getTourRow,
  updateBooking,
}

export default tourService
