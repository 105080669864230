import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { InputNumber } from "components"
import { DialogCloseButton } from "components/common"
import { DateTime } from "luxon"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useToggle } from "react-use"
import { queryClient, tourService } from "services"

type DialogController = PopupController & DialogProps

type Props = DialogController & {
  booking: TourBooking
}

const TourBookingDepositDialog = ({ booking, onClose, onSuccess, ...props }: Props) => {
  // const [isRequiredPayFull, toggleIsRequiredPayFull] = useToggle(false)

  // function handleCheckBookingEndTripDate(booking: TourBooking) {
  //   console.log("handleCheckBookingEndTripDate")
  //   const startDate = DateTime.fromISO(booking.startDate, { zone: "utc" })
  //   const currentDate = DateTime.local()
  //   const currentPlus8Days = currentDate.plus({ days: 8 })
  //   const result = currentPlus8Days > startDate
  //   toggleIsRequiredPayFull(result)
  // }

  const convertTourBookingToFormValues = (booking: TourBooking) => {
    if (!booking) return { totalPayment: 0 }
    // handleCheckBookingEndTripDate(booking)

    return {
      totalPayment: Math.floor(booking.totalPriceToBePaid / 2),
    }
  }

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<TourBookingRequestPaymentBody>({
    defaultValues: convertTourBookingToFormValues(booking),
  })

  useEffect(() => {
    if (!booking) return
    reset(convertTourBookingToFormValues(booking))
  }, [reset, booking])

  const paymentBookingMutation = useMutation({ mutationFn: tourService.bookingRequestPayment })

  const onSubmit = async (values: TourBookingRequestPaymentBody) => {
    if (Number(values.totalPayment) > booking.totalPriceToBePaid) {
      enqueueSnackbar("Số tiền yêu cầu đặt cọc phải nhỏ hơn hoặc bằng giá tour", { variant: "warning" })
      return
    }

    await paymentBookingMutation.mutateAsync({ id: booking.bookingCode, totalPayment: Number(values.totalPayment) })
    enqueueSnackbar("Gửi yêu cầu đặt cọc tour thành công")
    queryClient.invalidateQueries({ queryKey: ["tourService.getBooking"] })
    queryClient.invalidateQueries({ queryKey: ["tourService.fetchBookings"] })
    reset()
    onClose()
    onSuccess?.()
  }

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogCloseButton onClick={onClose} />
      <DialogTitle>Gửi yêu cầu đặt cọc Tour</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Controller
            control={control}
            name="totalPayment"
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Số tiền cọc"
                {...field}
                // disabled={isRequiredPayFull}
                error={!!error}
                helperText={error ? error.message : ""}
                InputProps={{ inputComponent: InputNumber }}
                required
              />
            )}
            rules={{
              required: "Số tiền cọc không được để trống",
            }}
          />

          {/*         
            <Typography color="error" variant="body2">
              Thời gian tính đến khởi hành còn lại nhỏ hơn 8 ngày, yêu cầu thanh toán toàn bộ tiền tour
            </Typography> */}

          <Typography variant="h6">Xác nhận gửi yêu cầu đặt cọc Tour ?</Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Đóng
        </Button>
        <LoadingButton color="secondary" loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant="outlined">
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default TourBookingDepositDialog
